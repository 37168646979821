import { Responsible, QueryActivityResponsibleArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { RESPONSIBLE_FIELDS } from "@/graphql/responsible/responsible.sdl";
import { useQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { USER_FIELDS } from "@/graphql/user/user.sdl";
import { reactive } from "vue";

type ActivityResponsibleData = {
  activityResponsible: Responsible[];
};
const ACTIVITY_RESPONSIBLE = gql`
    query ActivityResponsible($input: ResponsibleInput!) {
        activityResponsible(input: $input) {
            ${RESPONSIBLE_FIELDS}
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

export const useActivityResponsible = () => {
  const input = reactive({
    activityId: activeActivity.value.id,
  });
  const { result, loading: loadResponsible } = useQuery<
    ActivityResponsibleData,
    QueryActivityResponsibleArgs
  >(ACTIVITY_RESPONSIBLE, { input });
  const responsible = useResult<
    ActivityResponsibleData,
    Responsible[],
    Responsible[]
  >(result, [], (res) => {
    input.activityId = activeActivity.value.id;
    return res.activityResponsible;
  });
  return { loadResponsible, responsible };
};

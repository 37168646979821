import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoadError && _ctx.onLoadError(...args))),
    src: _ctx.src,
    alt: _ctx.alt
  }, null, 40, _hoisted_1))
}
import { NOT_STORABLE_FIELDS } from "@/graphql/product/not-storable";

export const STOCK_FIELDS = `
  id
  quantity
  note
  ${NOT_STORABLE_FIELDS}
  expiredAt
  alertAt
  createdAt
`;

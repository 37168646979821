import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "widget-person-list" }
const _hoisted_3 = { style: {"flex":"1"} }
const _hoisted_4 = { class: "person-item" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "person-info" }
const _hoisted_7 = { class: "person-name" }
const _hoisted_8 = { class: "person-subtext" }
const _hoisted_9 = { class: "person-subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("dashboard.employers")), 1),
    _createElementVNode("ul", _hoisted_2, [
      (_ctx.loadResponsible)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(5, (index) => {
            return _createElementVNode("li", {
              key: index,
              class: "p-d-flex"
            }, [
              _createVNode(_component_Skeleton, {
                width: "6rem",
                height: "3rem",
                class: "p-mr-2"
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Skeleton, {
                  width: "100%",
                  class: "p-mb-2"
                }),
                _createVNode(_component_Skeleton, { width: "75%" })
              ])
            ])
          }), 64))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.responsible, (res, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _ctx.getImage(res.user.image, 'users'),
                  alt: "africaPos-layout"
                }, null, 8, _hoisted_5),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(res.user.name), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$tm("activity.roles")[res.role]), 1),
                  _createElementVNode("small", _hoisted_9, _toDisplayString(_ctx.$t("workspaceLayout.lastConnexion")) + " : " + _toDisplayString(_ctx.$d(res.user.lastLoginAt, "long")), 1)
                ])
              ])
            ]))
          }), 128))
    ])
  ]))
}
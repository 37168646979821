export const USER_FIELDS = `
  id
  name
  isAdmin
  lastLoginAt
  resetPasswordCode
  activation
  language
  settings
  email
  image
  createdAt
`;

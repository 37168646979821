import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ac83a7f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-dashboard" }
const _hoisted_2 = { class: "p-d-flex p-justify-between p-mb-2" }
const _hoisted_3 = { class: "p-text-bold" }
const _hoisted_4 = { class: "p-d-inline-flex" }
const _hoisted_5 = { class: "p-grid" }
const _hoisted_6 = { class: "p-col-12 p-lg-4" }
const _hoisted_7 = { class: "p-col-12 p-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AdminIndex = _resolveComponent("AdminIndex")!
  const _component_PopularProducts = _resolveComponent("PopularProducts")!
  const _component_ActivityUsers = _resolveComponent("ActivityUsers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("rapport.period")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Dropdown, {
          class: "p-mr-2",
          modelValue: _ctx.input.month,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.month) = $event)),
          options: _ctx.months,
          "option-value": "value",
          "option-label": "label",
          "data-key": "value",
          style: {"border":"unset"}
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.input.year,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input.year) = $event)),
          options: _ctx.years,
          "option-value": "value",
          "option-label": "label",
          "data-key": "value",
          style: {"border":"unset"}
        }, null, 8, ["modelValue", "options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.userType < 2)
        ? (_openBlock(), _createBlock(_component_AdminIndex, {
            key: 0,
            input: _ctx.input
          }, null, 8, ["input"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_PopularProducts, { input: _ctx.input }, null, 8, ["input"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ActivityUsers)
      ])
    ])
  ]))
}

import { defineAsyncComponent, defineComponent, reactive, watch } from "vue";
import ActivityUsers from "@/components/dashboad/ActivityUsers.vue";
import PopularProducts from "@/components/dashboad/PopularProducts.vue";
import { useI18n } from "vue-i18n";
import { activeActivity } from "@/plugins/i18n";
import moment from "moment";
import { CONSTANTS } from "@/graphql/utils/utils";
import { stocksExpirations, useProducts } from "@/graphql/product/products";
import { stockExpNotifier } from "@/data/global";
import { secureLocalStorage } from "@/graphql/utils/encrypt-storage";

export default defineComponent({
  components: {
    ActivityUsers,
    PopularProducts,
    AdminIndex: defineAsyncComponent(
      () => import("@/components/dashboad/AdminIndex.vue")
    ),
  },
  setup() {
    const { tm } = useI18n();
    const months = (tm("prime.monthNames") as string[]).map((label, value) => ({
      label,
      value: value + 1,
    }));
    const years: { label: number; value: number }[] = [];
    const max = new Date().getFullYear();
    if (activeActivity.value.createdAt) {
      const mean = moment(activeActivity.value.createdAt).year();
      for (let year = mean; year <= max; year++)
        years.push({ label: year, value: year });
    } else years.push({ label: max, value: max });

    const input = reactive({
      activityId: activeActivity.value.id,
      month: new Date().getMonth() + 1,
      year: max,
    });

    const { products } = useProducts();

    watch(
      () => products.value,
      (prod) => {
        const both = stocksExpirations(prod);
        stockExpNotifier.expired = both[0].length;
        stockExpNotifier.threshold = both[1].length;
      },
      { immediate: true }
    );

    return {
      months,
      years,
      input,
      userType: secureLocalStorage.getItem(CONSTANTS.userType),
    };
  },
});

import { Product, QueryProductsArgs, Stock } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { PRODUCT_FIELDS } from "@/graphql/product/product";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { useQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { FilterMatchMode, FilterOperator, FilterService } from "primevue/api";
import { onBeforeMount, ref } from "vue";
import moment from "moment";
import { CONSTANTS, extractNumber, totalStock } from "@/graphql/utils/utils";

export type ProductsData = {
  products: Product[];
};

export const PRODUCTS = gql`
    query Products($activityId: Int!) {
        products(activityId: $activityId) {
            ${PRODUCT_FIELDS}
            stocks{${STOCK_FIELDS}}
        }
    }
`;

const PRICE_FILTERS = {
  EQUALS: "PRICE_EQUAL",
  DIFFERENT: "PRICE_DIFFERENT",
  SUPERIOR: "PRICE_SUPERIOR",
  INFERIOR: "PRICE_INFERIOR",
  INFERIOR_OR_EQUAL: "PRICE_INFERIOR_OR_EQUAL",
  SUPERIOR_OR_EQUAL: "PRICE_SUPERIOR_OR_EQUAL",
};

export const priceMatchModeOptions = [
  { label: "Egale à", value: PRICE_FILTERS.EQUALS },
  { label: "Différent de", value: PRICE_FILTERS.DIFFERENT },
  { label: "Inférieur à", value: PRICE_FILTERS.INFERIOR },
  { label: "Inférieur ou égale à", value: PRICE_FILTERS.INFERIOR },
  { label: "Superieur à", value: PRICE_FILTERS.SUPERIOR },
  { label: "Supérieur ou égale à", value: PRICE_FILTERS.SUPERIOR_OR_EQUAL },
];

const FILTERS_ = {
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  id: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  sellingPrices: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: PRICE_FILTERS.EQUALS }],
  },
  totalSellingPrices: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: PRICE_FILTERS.EQUALS }],
  },
  purchasePrices: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: PRICE_FILTERS.EQUALS }],
  },
  totalPurchasePrices: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: PRICE_FILTERS.EQUALS }],
  },
  type: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  reference: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  "category.id": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  quantity: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  stock: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  gap: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  threshold: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

type ExpStock = Stock & {
  name: string;
  gap: number;
  threshold?: number;
};

export const useProducts = () => {
  const { loading, result } = useQuery<ProductsData, QueryProductsArgs>(
    PRODUCTS,
    {
      activityId: activeActivity.value.id,
    }
  );
  const products = useResult<ProductsData, Product[], Product[]>(
    result,
    [],
    (res) => res.products
  );

  return { loading, products };
};

export const useProductsFilter = () => {
  const filters = ref(FILTERS_);
  function clearFilter() {
    Object.assign(filters.value, FILTERS_);
    filters.value.global.value = "";
  }
  const productColumns = [
    "product.reference",
    "product.category",
    "product.name",
    "image",
    "product.type",
    "stock.quantity",
    "product.purchasePrice",
    "product.sellingPrice",
    "createdAt",
    "controls",
  ];
  const storeData = localStorage.getItem(CONSTANTS.productVisibleCols);
  const visibleColumns = ref(
    storeData ? JSON.parse(storeData) : productColumns
  );
  function onChangeVisible(event: string[]) {
    if (event.length)
      localStorage.setItem(CONSTANTS.productVisibleCols, JSON.stringify(event));
    else localStorage.removeItem(CONSTANTS.productVisibleCols);
  }

  function extractPrices(value: string) {
    return value
      .toString()
      .split("/")
      .filter((v) => !!v.trim())
      .map((v) => extractNumber(v));
  }
  onBeforeMount(() => {
    FilterService.register(PRICE_FILTERS.EQUALS, (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }
      const prices = extractPrices(value.toString());
      return prices.includes(filter);
    });

    FilterService.register(PRICE_FILTERS.DIFFERENT, (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }
      value = value.toString();
      return extractPrices(value).filter((v) => v !== filter).length > 0;
    });

    FilterService.register(PRICE_FILTERS.INFERIOR, (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }
      value = value.toString();
      return extractPrices(value).filter((v) => v < filter).length > 0;
    });

    FilterService.register(PRICE_FILTERS.INFERIOR_OR_EQUAL, (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }
      value = value.toString();
      return extractPrices(value).filter((v) => v <= filter).length > 0;
    });

    FilterService.register(PRICE_FILTERS.SUPERIOR, (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }
      value = value.toString();
      return extractPrices(value).filter((v) => v > filter).length > 0;
    });

    FilterService.register(PRICE_FILTERS.SUPERIOR_OR_EQUAL, (value, filter) => {
      if (filter === undefined || filter === null) {
        return true;
      }
      value = value.toString();
      return extractPrices(value).filter((v) => v >= filter).length > 0;
    });
  });
  return {
    filters,
    clearFilter,
    visibleColumns,
    productColumns,
    onChangeVisible,
    priceMatchModeOptions,
  };
};

export const stocksExpirations = (
  products: readonly Product[]
): Array<Array<ExpStock>> => {
  const today = new Date();
  return products.reduce(
    (sum: Array<Array<ExpStock>>, prod) => {
      if (prod.stocks && prod.stocks.length > 0) {
        prod.stocks.forEach((stock) => {
          let expired = false;
          if (stock.expiredAt) {
            if (moment(stock.expiredAt).isBefore(today)) {
              expired = true;
              const quantity = totalStock(prod.stocks);
              sum[0].push({
                ...stock,
                createdAt: new Date(stock.createdAt),
                expiredAt: new Date(stock.expiredAt) as any,
                name: prod.name,
                quantity,
                gap: quantity - (prod.threshold || 0),
                threshold: prod.threshold,
              });
            } /*else if (stock.alertAt && moment(stock.alertAt).isBefore(today)) {
              sum[1].push({
                ...stock,
                name: prod.name,
                threshold: prod.threshold,
                quantity: totalStock(prod.stocks),
                expired: moment(stock.expiredAt).diff(today, "day"),
              });
            }*/
          }
          if (!expired && prod.threshold) {
            const quantity = totalStock(prod.stocks);
            if (quantity < prod.threshold) {
              sum[1].push({
                ...stock,
                createdAt: new Date(stock.createdAt),
                expiredAt: stock.expiredAt
                  ? (new Date(stock.expiredAt) as any)
                  : null,
                threshold: prod.threshold,
                name: prod.name,
                gap: quantity - (prod.threshold || 0),
                quantity,
              });
            }
          }
        });
      }
      return sum;
    },
    [[], []]
  );
};

import { NOT_STORABLE_FIELDS } from "@/graphql/product/not-storable";

export const PRODUCT_FIELDS = `
    id
    name
    reference
    image
    type
    threshold
    changeable
    notStorable {
        ${NOT_STORABLE_FIELDS}
    }
    category {
        id 
        label
    }
    createdAt
`;

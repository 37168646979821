
import { defineComponent } from "vue";
import { usePopularProducts } from "@/graphql/dashboard/popular.products";
import ImagePrinter from "@/components/common/ImagePrinter.vue";

export default defineComponent({
  name: "PopularProducts",
  props: ["input"],
  components: {
    ImagePrinter,
  },
  setup(props) {
    return {
      ...usePopularProducts(props.input),
    };
  },
  beforeMount() {
    this.loader();
  },
});


import { defineComponent } from "vue";
import { useActivityResponsible } from "@/graphql/responsible/activity.responsible";
import { getImage } from "@/graphql/utils/utils";

export default defineComponent({
  name: "ActivityUsers",
  setup() {
    return {
      ...useActivityResponsible(),
      getImage,
    };
  },
});

import { USER_FIELDS } from "@/graphql/user/user.sdl";

export const RESPONSIBLE_FIELDS = `
    id
    inviter {${USER_FIELDS}}
    roleId
    role { id activityId name }
    accepted
    createdAt
`;

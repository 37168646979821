
import { getImage } from "@/graphql/utils/utils";
import { computed, defineComponent, ref } from "vue";
export default defineComponent({
  name: "ImagePrinter",
  props: {
    image: {
      type: String,
      default: "",
    },
    folder: {
      type: String,
      default: "products",
    },
  },
  setup(props) {
    const errorLoad = ref(false);
    const src = computed(() =>
      getImage(`${errorLoad.value ? "" : props.image}`, `${props.folder}`)
    );
    const onLoadError = () => (errorLoad.value = true);
    return {
      src,
      alt: process.env.VUE_APP_NAME || "",
      onLoadError,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "widget-image-list" }
const _hoisted_3 = { style: {"flex":"1"} }
const _hoisted_4 = { class: "listitem-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ImagePrinter = _resolveComponent("ImagePrinter")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("dashboard.bestSellers")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("dashboard.mostPopulars")), 1),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("product.product")), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("dashboard.sales")), 1)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(5, (index) => {
            return _createElementVNode("li", {
              key: index,
              class: "p-d-flex"
            }, [
              _createVNode(_component_Skeleton, {
                width: "6rem",
                height: "3rem",
                class: "p-mr-2"
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Skeleton, {
                  width: "100%",
                  class: "p-mb-2"
                }),
                _createVNode(_component_Skeleton, { width: "75%" })
              ])
            ])
          }), 64))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.popularProducts, (pop, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("span", null, [
                _createVNode(_component_ImagePrinter, {
                  image: pop.image
                }, null, 8, ["image"]),
                _createElementVNode("span", null, _toDisplayString(pop.name), 1)
              ]),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_NumberFormatter, {
                  value: pop.count
                }, null, 8, ["value"])
              ])
            ]))
          }), 128))
    ])
  ]))
}
import {
  PopularProductsOutput,
  QueryPopularProductsArgs,
  TicketsVolumeInput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery, useResult } from "@vue/apollo-composable";

type PopularProductsData = {
  popularProducts: PopularProductsOutput[];
};

const POPULAR_PRODUCTS = gql`
  query PopularProducts($input: TicketsVolumeInput!) {
    popularProducts(input: $input) {
      name
      count
      image
    }
  }
`;

export const usePopularProducts = (input: TicketsVolumeInput) => {
  const { loading, result, load } = useLazyQuery<
    PopularProductsData,
    QueryPopularProductsArgs
  >(POPULAR_PRODUCTS, { input });
  function loader() {
    void load();
  }
  const popularProducts = useResult<
    PopularProductsData,
    PopularProductsOutput[],
    PopularProductsOutput[]
  >(result, [], (res) => res.popularProducts);
  return { loading, popularProducts, loader };
};
